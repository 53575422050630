import React, { useState, useEffect } from 'react';
import { Field } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import './FormField.scss';
import DateIcon from '../../images/date-icon.svg';

const DateField = ({
  error,
  touched,
  setFieldValue,
  isResettingDate,
  setIsResettingDate,
  ...props
}) => {
  const [startDate, setStartDate] = useState();

  useEffect(() => {
    if (isResettingDate) {
      setStartDate();
      setIsResettingDate(false);
    }
  }, [isResettingDate, setIsResettingDate]);

  const CustomDateInputPicker = React.forwardRef(({ onClick, value }, ref) => {
    return (
      <Field
        className={`user-input ${error && touched ? 'error' : ''}`}
        {...props}
        onClick={onClick}
        innerRef={ref}
        value={value}
      />
    );
  });

  return (
    <div className='field-wrapper'>
      <DatePicker
        showIcon
        selected={startDate}
        onChange={(date) => {
          setStartDate(date);
          setFieldValue(props.name, date);
        }}
        customInput={<CustomDateInputPicker />}
      />
      <div className='calendar-icon'>
        <DateIcon width={24} height={24} />
      </div>
    </div>
  );
};

export default DateField;
