import React, { useMemo } from 'react';

import './FormField.scss';
import UploadIcon from '../../images/upload-icon.svg';

const FileField = ({ label, id, setFormFiles, formFiles }) => {
  const fileName = useMemo(() => {
    if (formFiles && formFiles.length > 0) {
      const file = formFiles.find((f) => f.button === label);

      if (file) return file.file[0].name;
    }
  }, [formFiles, label]);

  return (
    <div className='upload-field'>
      <label htmlFor={`upload-${id}`} className='button-upload'>
        <UploadIcon width={20} height={20} />
        {label}
      </label>
      <input
        type='file'
        id={`upload-${id}`}
        className='file-input'
        onChange={(event) => {
          setFormFiles((prevFiles) => {
            if (prevFiles.length > 0) {
              const exisitngFiles = prevFiles.filter(
                (prevFile) => prevFile.button !== label
              );

              if (exisitngFiles.length > 0) {
                return [
                  ...exisitngFiles,
                  {
                    button: label,
                    file: event.target.files,
                  },
                ];
              } else {
                return [
                  {
                    button: label,
                    file: event.target.files,
                  },
                ];
              }
            } else {
              return [
                {
                  button: label,
                  file: event.target.files,
                },
              ];
            }
          });
        }}
        accept='.pdf, .doc, .docx, .txt, .csv, .xlsx'
      />
      {fileName && <label className='file-input-name'>{fileName}</label>}
    </div>
  );
};

export default React.memo(FileField);
