import React from 'react';

import './Form.scss';
import InternshipForm from './InternshipForm';
import DownloadIcon from '../../images/download-icon.svg';

const ApplyForm = ({ data }) => {
  return (
    <div className='form-wrapper'>
      <h2 className='career-form__heading'>
        {data.attributes.application_form_title}
      </h2>
      <div className='form-heading'>
        {data.attributes.assessment_exercise_content &&
          data.attributes.assessment_exercise_file.data &&
          data.attributes.assessment_exercise_file.data.attributes &&
          data.attributes.assessment_exercise_file.data.attributes.url && (
            <>
              <h4 className='career-form__info-title'>
                {data.attributes.assessment_exercise_content}
              </h4>
              <a
                href={
                  data.attributes.assessment_exercise_file.data.attributes.url
                }
                target='_blank'
                rel='noreferrer'
                download
                className='button download-btn'
              >
                Download file <DownloadIcon width={18} height={18} />
              </a>
            </>
          )}
      </div>
      <InternshipForm data={data} />
    </div>
  );
};

export default ApplyForm;
