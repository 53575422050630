import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import './index.scss';
import './careers.scss';
import cmsApiSerivce from '../api/cmsApiService';
import Navbar, { NavbarMobile } from '../components/NavBar/Navbar';
import JobItem from '../components/JobItem/JobItem';
import Loading from '../components/Loading';
import Footer from '../components/Footer';

const CareersPage = () => {
  const [jobsList, setJobsList] = useState(undefined);

  useEffect(() => {
    const getPublicCareers = async () => {
      const data = await cmsApiSerivce().get(
        'public-careers?populate=buttons,assessment_exercise_file'
      );
      setJobsList(data.data);
    };

    getPublicCareers();
  }, []);

  return (
    <>
      <Helmet>
        <title>Wigram - Macro economic analysis and research</title>
        <meta
          name='description'
          content='Wigram provides leading analysis and research to leading global hedge funds with a focus on Asia-Pacific markets.'
        />
      </Helmet>

      <div className='careers-listing-wrapper'>
        <NavbarMobile showLogin={false} />
        <div className='container careers-nav-desktop'>
          <Navbar showLogin={false} isDarkLogo={true} />
        </div>

        <section className='hero-body careers-page'>
          <div className='container'>
            <div className='content-heading'>
              <h1>Careers</h1>
            </div>
            {jobsList === undefined ? (
              <Loading />
            ) : (
              <div className='career-jobs'>
                <ul className='job-list'>
                  {jobsList.length > 0 ? (
                    <>
                      {jobsList.map((item, idx) => (
                        <React.Fragment key={idx}>
                          <JobItem data={item} />
                        </React.Fragment>
                      ))}
                    </>
                  ) : (
                    <p className='careers-page-description'>
                      We currently do not have any open positions. To register
                      your interest for future positions send a cover letter and
                      CV to{' '}
                      <a href='mailto:careers@wigramcap.com'>
                        careers@wigramcap.com
                      </a>
                    </p>
                  )}
                </ul>
              </div>
            )}
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default CareersPage;
