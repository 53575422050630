import React from 'react';
import { Field } from 'formik';

import './FormField.scss';

const TextField = ({ error, touched, ...props }) => {
  return (
    <div className='field-wrapper'>
      <Field
        className={`user-input ${error && touched ? 'error' : ''}`}
        {...props}
      />
    </div>
  );
};

export default TextField;
