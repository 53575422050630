import { BASE_URLS } from '../common/constants';
import commonAxios from './commonAxios';

const getApiBaseUrl = () => {
  let baseUrl = BASE_URLS.API;

  if (process.env.GATSBY_API_URL) {
    let envBaseUrl = process.env.GATSBY_API_URL;
    if (!envBaseUrl.endsWith('/')) {
      baseUrl = envBaseUrl + '/';
    } else {
      baseUrl = envBaseUrl;
    }
  }

  console.log('-> Computed API base URL: ' + baseUrl);
  return baseUrl;
};

const apiService = (token, useDefaultHeader = true) =>
  commonAxios(getApiBaseUrl(), token, useDefaultHeader);

export default apiService;
