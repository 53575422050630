import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import * as moment from 'moment';

import TextField from '../FormField/TextField';
import DateField from '../FormField/DateField';
import FileField from '../FormField/FileField';
import apiService from '../../api/apiService';
import Loading from '../Loading';

const InternshipForm = ({ data }) => {
  const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

  const initialValues = {
    name: '',
    phone: '',
    email: '',
    startDate: '',
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    phone: Yup.string().required().trim().matches(phoneRegExp),
  });
  const [formFiles, setFormFiles] = useState([]);
  const [isResettingDate, setIsResettingDate] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          const formFieldsData = {
            strapi_career_article_id: data.id,
            email: values.email,
            first_name: values.name.split(' ')[0],
            last_name:
              values.name.split(' ').length > 1
                ? values.name.split(' ').slice(1).join(' ')
                : values.name,
            phone: values.phone.trim(),
            preferred_start_date: values.startDate
              ? moment.utc(values.startDate).format('yyyy-MM-DD HH:mm:ss')
              : '',
            strapi_career_name: data.attributes?.title,
          };
          const file_content = [];

          const formData = new FormData();

          if (formFiles && formFiles.length > 0) {
            formFiles.forEach((file) => {
              file_content.push({
                file_name: file.file[0].name,
              });
              formData.append('file-attachments-binary', file.file[0]);
            });
          }

          const postData = {
            ...formFieldsData,
            application_content: JSON.stringify({
              ...formFieldsData,
              files: file_content,
            }),
          };

          formData.append(
            'career-application-dto',
            new Blob([JSON.stringify(postData)], {
              type: 'application/json',
            })
          );

          setIsSubmitting(true);
          return await apiService(undefined, false)
            .post('/api/v1/webhook/career-application-inbound', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .catch((err) => console.log(err))
            .finally(() => {
              resetForm();
              setFormFiles([]);
              setIsResettingDate(true);
              setIsSubmitting(false);
            });
        }}
      >
        {({ handleSubmit, errors, touched, setFieldValue }) => (
          <Form>
            <TextField
              name='name'
              placeholder='Name*'
              error={errors.name}
              touched={touched.name}
            />
            <TextField
              name='phone'
              placeholder='Phone'
              type='tel'
              error={errors.phone}
              touched={touched.phone}
            />
            <TextField
              name='email'
              placeholder='E-mail*'
              error={errors.email}
              touched={touched.email}
            />
            <DateField
              name='startDate'
              placeholder='Preferred starting date'
              setFieldValue={setFieldValue}
              isResettingDate={isResettingDate}
              setIsResettingDate={setIsResettingDate}
            />
            <div className='upload-buttons'>
              {data.attributes.buttons && data.attributes.buttons.length > 0 && (
                <>
                  {data.attributes.buttons.map((button, idx) => (
                    <FileField
                      key={button.id}
                      label={button.buttons}
                      id={button.id}
                      setFormFiles={setFormFiles}
                      formFiles={formFiles}
                    />
                  ))}
                </>
              )}
            </div>
            <div className='submit-btn-wrapper'>
              <button
                type='submit'
                className='button submit-btn'
                onClick={handleSubmit}
              >
                Submit
              </button>
              {isSubmitting && (
                <div className='submit-loading'>
                  <Loading type={'small'} color={'dark'} />
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default React.memo(InternshipForm);
