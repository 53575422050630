import React, { useState, useRef, useCallback, useEffect } from 'react';
import * as moment from 'moment';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

import ApplyForm from '../Form/ApplyForm';
import './JobItem.scss';

const JobItem = ({ data }) => {
  const jobDetailRef = useRef(null);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [canOverflow, setCanOverflow] = useState(false);
  const [jobDetailHeight, setJobDetailHeight] = useState(0);

  const handleSetJobDetailHeight = useCallback(() => {
    if (jobDetailRef.current) {
      setJobDetailHeight(jobDetailRef.current.clientHeight);
    }
  }, [jobDetailRef]);

  const handleFinishTransition = useCallback(() => {
    if (isOpenDetail) {
      setCanOverflow(true);
    } else setCanOverflow(false);
  }, [isOpenDetail]);

  const handleToggleJobDetail = useCallback(() => {
    if (jobDetailRef.current) {
      setJobDetailHeight(jobDetailRef.current.clientHeight);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== undefined) {
      window.addEventListener('resize', () => handleSetJobDetailHeight());
    }

    return () => {
      if (typeof window !== undefined) {
        window.removeEventListener('resize', () => handleSetJobDetailHeight());
      }
    };
  }, [jobDetailRef, handleSetJobDetailHeight]);

  useEffect(() => {
    handleSetJobDetailHeight();
  }, [jobDetailRef, handleSetJobDetailHeight]);

  return (
    <li className='job-item'>
      <div
        className='job-heading'
        onClick={() => {
          setIsOpenDetail(!isOpenDetail);
          handleToggleJobDetail();
        }}
        role='list'
      >
        <h2 className='job-title'>
          <span className='title'>{data.attributes.title}</span>
          <span className={`expand-icon ${isOpenDetail ? 'is-expanding' : ''}`}>
            <span></span>
            <span></span>
          </span>
        </h2>
        <div className='job-type'>
          <span>{data.attributes.type}</span>
        </div>
        <div className='mt-5'>
          {moment(data.attributes.datetime).format('DD MMMM YYYY')}
        </div>
      </div>
      <div
        className='detail-wrapper'
        style={{
          maxHeight: isOpenDetail ? `${jobDetailHeight}px` : '0px',
          overflow: `${
            !isOpenDetail ? 'hidden' : canOverflow ? 'unset' : 'hidden'
          }`,
        }}
        onTransitionEnd={handleFinishTransition}
      >
        <div ref={jobDetailRef} className='job-detail'>
          <main className='career-main'>
            <div className='job-description'>
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
                className='job-descr-markdown-custom'
              >
                {data.attributes.job_description}
              </ReactMarkdown>
            </div>
          </main>
          <aside className='career-aside'>
            <ApplyForm data={data} />
          </aside>
        </div>
      </div>
    </li>
  );
};

export default React.memo(JobItem);
