import React from 'react';

import './Loading.scss';

const Loading = ({ type, color }) => {
  return (
    <div className='loader-wrapper'>
      <span
        className={`loader ${type === 'small' ? 'loader--small' : ''} ${
          color === 'dark' && 'loader--dark'
        }`}
      ></span>
    </div>
  );
};

export default Loading;
